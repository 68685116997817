/* @font-face {
  font-family: 'Helvetica Neue';
  src: url('../Shared/helveticaneue.ttf') format('ttf');
} */

body {
  margin: 0;
  font-family: 'helvetica neue', 'Helvetica Neue', 'Arial', 'Georgia';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

